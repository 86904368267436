import React from 'react';
import '../App.css';
import Loading from '../components/Loading';
import Home from "../components/Home";
// import Morph from '../components/Morph';
import AI from '../components/AI';
import AICapabilities from '../components/AICapabilities';
import AITechnologies from '../components/AITechnologies';
import CloudSolutions from '../components/CloudSolutions';
import Services from '../components/Services';
import Company from '../components/Company';
import Platforms from '../components/Platforms';
import Industries from '../components/Industries';
import Technologies from '../components/Technologies';
import Careers from '../components/Careers';


function Layout() {

    return (

        <div className="App">

            <Loading />
            <Home />
            {/* <Morph /> */}
            <AI />
            <AICapabilities />
            <AITechnologies />
            <CloudSolutions />
            <Services />
            <Company />
            <Platforms />
            <Industries />
            <Technologies />
            <Careers />

        </div>
    )
};

export default Layout;