import React, { useState, useEffect, useMemo } from 'react';
import './Home.css';
// import Morph from './Morph';

const Home = () => {
  const words = useMemo(() => ['Digital', 'AI-Driven APPs', 'Low-Code Automation', 'Data as a Service', 'SaaS Solutions', 'Conversational Agents', 'MLOps', 'AI Consulting', 'Offshore Development', 'MVP Development'], []);
  const [heading, setHeading] = useState('');
  const [currentWord, setCurrentWord] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopNum, setLoopNum] = useState(0);
  const [typingSpeed, setTypingSpeed] = useState(150);
  const [animationStage, setAnimationStage] = useState('heading');
  const [showFirstText, setShowFirstText] = useState(false);
  const [showSecondText, setShowSecondText] = useState(false);
  const [currentSingleWord, setCurrentSingleWord] = useState('');
  const [wordIndex, setWordIndex] = useState(0);
  const [animationInterval, setAnimationInterval] = useState(2000);

  useEffect(() => {
    if (animationStage === 'heading') {
      const headingText = 'Your full-service global partner for everything';
      const handleType = () => {
        setHeading(prev => {
          if (!isDeleting) {
            const nextIndex = currentIndex + 1;
            if (nextIndex > headingText.length) {
              setAnimationStage('words');
              return headingText;
            }
            return headingText.substring(0, nextIndex);
          } else {
            const nextIndex = currentIndex - 1;
            if (nextIndex <= 0) {
              setIsDeleting(false);
              return headingText;
            }
            return headingText.substring(0, nextIndex);
          }
        });
        setTypingSpeed(isDeleting ? 50 : 50);
        setCurrentIndex(prev => (isDeleting ? prev - 1 : prev + 1));
      };

      const timer = setTimeout(handleType, typingSpeed);
      return () => clearTimeout(timer);
    } else if (animationStage === 'words') {
      const handleType = () => {
        const fullWord = words[loopNum % words.length];
        setCurrentWord(
          isDeleting
            ? fullWord.substring(0, currentWord.length - 1)
            : fullWord.substring(0, currentWord.length + 1)
        );

        setTypingSpeed(isDeleting ? 50 : 150);

        if (!isDeleting && currentWord === fullWord) {
          setTimeout(() => {
            setIsDeleting(true);
            if (loopNum === 0 && currentWord === words[0]) {
              setShowFirstText(true);
            }
          }, 1000);
        } else if (isDeleting && currentWord === '') {
          setIsDeleting(false);
          setLoopNum(loopNum + 1);
          if (loopNum === 0) {
            setTimeout(() => setShowSecondText(true), 500);
          }
        }
      };

      const timer = setTimeout(handleType, typingSpeed);
      return () => clearTimeout(timer);
    } else if (animationStage === 'singleWord') {
      const handleSingleWordAnimation = () => {
        setCurrentSingleWord(words[wordIndex % words.length]);
        setWordIndex(prev => (prev + 1) % words.length);
      };

      const interval = setInterval(handleSingleWordAnimation, animationInterval);

      return () => clearInterval(interval);
    }
  }, [currentWord, isDeleting, loopNum, typingSpeed, currentIndex, animationStage, words, wordIndex, animationInterval]);

  useEffect(() => {
    if (animationStage === 'words' && loopNum >= words.length) {
      setAnimationStage('singleWord');
    }
  }, [loopNum, animationStage]);

  return (
    <div id="home" className="home">
      <div className="home-content">
        <h1>
          {animationStage === 'heading' ? (
            <>
              {heading}
              <span className="cursor cursor-white"></span>
            </>
          ) : animationStage === 'words' ? (
            <>
              Your full-service global partner for everything{' '}
              <span style={{ color: '#00c5d3' }}>{currentWord}</span>
              <span className="cursor cursor-colored"></span>
            </>
          ) : (
            <>
              Your full-service global partner for everything{' '}
              <span style={{ color: '#00c5d3' }}>{currentSingleWord}</span>
             <span className="cursor cursor-hidden"></span> {/* Hide cursor here */}
              {/*  <Morph /> {/* Add Morph component here */}  
            </>
          )}
        </h1>
        <p className={`fade-in ${showFirstText ? 'visible' : ''}`}>
          We build next-gen solutions, that drive your business and boost sales,
          <br />
          making an impact that matters
        </p>
        <div className={`home-aicontent fade-in ${showSecondText ? 'visible' : ''}`}>
          <h1>AI Consulting</h1>
          <p>
            Empower Your Business With Artificial Intelligence,
            <br /> faster and cost-effectively
          </p>
        </div>
      </div>
    </div>
  );
}

export default Home;